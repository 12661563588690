@font-face {
    font-family: 'Gilroy-Medium';
    src: url('./Gilroy-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('./Gilroy-SemiBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}