.header {
  position: relative;
  background-color: #EFF4FF;
  height: 60px;
  width: 100%;
  color: black;
  font-family: Gilroy-SemiBold, sans-serif;
  z-index: 500;
  padding: 3px;
}

.pre-nav {
  float: left;
  padding-left: 20px;
}

.title {
  position: relative;
  font-weight: bold;
  font-size: 30px;
  top: 5px;
  font-family: Gilroy-SemiBold, sans-serif;
}

.welcome {
  position: relative;
  font-size: 18px;
  top: -3px;

}

.nav {
  float: left;
  padding-left: 20px;
}

button.nav-item {
  font-size: 18px;
  font-family: Gilroy-SemiBold, sans-serif;
  display: inline-block;
  background-color: #EFF4FF;
  color: black;
  text-decoration: none;
  padding: 15px 15px 13px;
  margin-top: 8px;
  margin-right: 15px;
  vertical-align: middle;
  border-radius: 5px;
  line-height: 1.0;
  letter-spacing: 0;
  text-transform: none;
}

button.nav-item:hover,
button.current-nav-item {
  background-color: #81a1c1;
  color: white;
}

.clear {
  float: none;
  clear: both;
}

.app {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.retool-app {
  position: fixed;
  z-index: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 60px; /* this is the height of the retool header */
}

.auth-check {
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
}

.tertiary-nav {
  width: 130px;
  flex-shrink: 0;
}

.tertiary-nav .MuiDrawer-paper {
  width: 130px;
  top: 60px;
}

.retool-app-with-tertiary-nav {
  position: fixed;
  z-index: 0;
  left: 130px;
  right: 0px;
  bottom: 0px;
  top: 60px; /* this is the height of the retool header */
}
